import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import 'uikit/dist/css/uikit.css'

import "../styles/contact-us.scss";
import { BsTelephone } from "react-icons/bs"
import { BiEnvelope } from "react-icons/bi"
import { FiMapPin } from "react-icons/fi"
import Button from "../components/button/button";

import Layout from "../layouts"

import { Helmet } from "react-helmet"

import Header from "../components/header/header"




// markup
const Contact = () => {

    const data = useStaticQuery(graphql`
        {
            strapiGlobal {
                ContactDetails {
                  Email
                  Phone
                }
              }
            strapiContact {
                PageComponents
                Seo {
                  metaDescription
                  metaTitle
                }
              }
              allFile {
                edges {
                  node {
                    publicURL
                    id
                    name
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 1900) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
        }
    `)





    function getImgUrl(localFile___NODE) {

        var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

        data.allFile.edges.map((item, index) => {

            if (item.node.id === localFile___NODE) {
                // if(item.node.publicURL.endsWith(".svg")){
                //   imgUrl.basicUrl = item.node.publicURL
                // } else {
                //   imgUrl.fluid = item.node.childImageSharp.fluid
                //   imgUrl.basicUrl = item.node.publicURL
                // }
                imgUrl.imgName = item.node.name ? item.node.name : null
                imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
                imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
                imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

                // imgUrl = item.node.publicURL
            }
            return ("")

        })
        // console.log(imgUrl)
        return (imgUrl)
    }


    // const { siteUrl: url } = useSiteMetadata()

    // let richSnippetData = {
    //   '@context': "https://schema.org",
    //   "@type": "Organization",
    //   name: `${data.strapiSiteSettings.SiteTitle}`,
    //   url: `${url}`,
    //   logo: `${url + data.strapiSiteSettings.LogoDark.localFile.publicURL}`,
    //   sameAs: [data.strapiSiteSettings.SocialLinks.FacebookURL, data.strapiSiteSettings.SocialLinks.TwitterURL, data.strapiSiteSettings.SocialLinks.LinkedinURL, data.strapiSiteSettings.SocialLinks.YoutubeURL]
    // }

    const [msgError, setMsgError] = useState(false);

    const [form, setForm] = useState({
        pageType: "contact",
        name: "",
        phone: "",
        email: "",
        enquiry: "",
        addInfo: "",
        about: "",
    });

    const onUpdateField = e => {
        const nextFormState = {
            ...form,
            [e.target.name]: e.target.value,
        };
        setForm(nextFormState);
    };

    const [error, setError] = useState({
        nameError: '',
        phoneError: '',
        enquiryError: '',
        aboutError: ''
    });

    const validate = async (e) => {

        let nameError = "";
        let phoneError = "";
        let enquiryError = "";
        let aboutError = "";
        let isNotValid = false;

        if (form.name === "") {
            nameError = "This field is required.";
            isNotValid = true;
        }
        if (form.phone === "") {
            phoneError = "This field is required.";
            isNotValid = true;
        }
        if (form.enquiry === "") {
            enquiryError = "This field is required.";
            isNotValid = true;
        }
        if (form.about === "") {
            aboutError = "This field is required.";
            isNotValid = true;
        }

        setError({ ...error, nameError: nameError, phoneError: phoneError, enquiryError: enquiryError, aboutError: aboutError });
        return isNotValid;

    }

    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false);

    const submitForm = async (e) => {

        e.preventDefault();

        let value = await validate();
        console.log(value, "value");
        setMsgError(value);

        setLoading(true)

        var body = {
            Token: 'phARMocDrnKoPgKl0zcjMOsdjfh7M2ZeaWrnn1',
            FormData: JSON.stringify(form),
            PageType: 'contact'
        };

        // !value && fetch("https://api.phaeria.com/api/home/SendEmailNotification", {

        //     method: "POST",
        //     body: JSON.stringify(body),
        //     headers: {

        //         'Content-Type': 'application/json',
        //         'redirect': 'follow',
        //         'mode': 'no-cors',
        //     }
        // }).then(response => response.text().then(function (responseText) {
        //     setLoading(false);
        //     if (response.status === 200) {
        //         setMessage("Thanks for your message!")
        //     }
        //     else {
        //         setMessage("Something gone wrong...Please try again later!")
        //     }
        // }))


        let spamDomains = ["mailinator", "aosod.com", "guerrillamail", "spam4.me", "pokemail.net", "sharklasers.com", "tcwlx.com", "yopmail.com", "nfstripss.com", "maildrop.cc"];
        let isSpam = 0
        spamDomains.forEach(function (domain, index) {
            if (JSON.stringify(form).includes(domain)) {
                isSpam = 1;
            }
        });

        if (isSpam == 0 && !value) {
            fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response =>
                response.text().then(function (responseText) {
                    console.log("response text: " + responseText);
                    setLoading(false);
                    if (response.status === 200) {
                        setMessage("Thanks for your message!")
                    }
                    else {
                        setMessage("Something gone wrong...Please try again later!")
                    }
                })
            )
        } else {
            setMessage("❗ Your message cannot be sent. Please try again with another email address.")
            console.log("Spam.")
        }
    }

    const handleGtagContactForm = () => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', 'contact-form');

        }
    }


    return (
        <Layout SEOTitle={data.strapiContact.Seo.metaTitle} SEODescription={data.strapiContact.Seo.metaDescription} footerDisclaimer={data.strapiContact.FooterDisclaimer}>

            {/** List of meta properties coming from strapi that applies only for the homepage */
                <Helmet>
                    {data.strapiContact.MetaTags?.map((item, index) => {
                        return (
                            <>

                                <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
                            </>
                        )
                    })}
                </Helmet>
            }

            {data.strapiContact?.PageComponents?.map((item, index) => {
                switch (item.strapi_component) {

                    case "page-components.header":
                        return (
                            <>
                                {/* <RichSnippet type={"Organization"} data={richSnippetData} /> */}
                                <Header key={index}
                                    Title={item.Title}
                                    Subtitle={item.Subtitle}
                                    FirstCTALabel={item.FirstCTALabel}
                                    FirstCTAURL={item.FirstCTAURL}
                                    Background={item.Background !== null ? getImgUrl(item.Background.localFile___NODE) : ""}
                                    Image={item.Image !== null ? getImgUrl(item.Image.localFile___NODE) : null}
                                    BackgroundColor={item.BackgroundColorHeader}
                                ></Header>
                            </>

                        )

                    default:
                        return ("")
                }
            })}







            <div className="contact-page-container">
                <div className="uk-child-width-1-2@s uk-child-width-1-1@xs uk-grid-small uk-grid" uk-grid="true">
                    <div className="contact-info">
                        <h2 className="contact-title"> Get in Touch</h2>
                        <div className="section1">
                            {
                                data.strapiGlobal.ContactDetails.Phone ? <p><BsTelephone color="#0050a9" fontSize="1.2em" /><a href="tel:+01704834363"> {data.strapiGlobal.ContactDetails.Phone}</a></p> : ""
                            }

                            {
                                data.strapiGlobal.ContactDetails.Email ? <p><BiEnvelope color="#0050a9" fontSize="1.2em" /><a href="mailto:office@towerheating.com"> {data.strapiGlobal.ContactDetails.Email}</a></p> : ""
                            }
                            <p><FiMapPin color="#0050a9" fontSize="1.2em" /> Tower Heating Ltd</p>
                            <div className="address">
                                <p>47A Liverpool Road,</p>
                                <p>Formby,</p>
                                <p>Liverpool,</p>
                                <p>L37 6BT</p>
                            </div>
                        </div>
                        <div className="section2-1">
                            <p><b>Office Hours:</b></p>
                            <p>8am-5pm, weekdays.</p>
                            <p>Except Bank Holidays</p>
                        </div>
                        <div className="section2-2">
                            <p><b>Emergency Hours:</b></p>
                            <p>8am-8pm, everyday.</p>
                        </div>

                    </div>

                    <div className="contact-form uk-background-muted">

                        <form className="form uk-grid-large uk-grid" uk-grid="true" id="form-contact" onSubmit={submitForm}>
                            <div className="input-margin uk-width-1-2@s uk-child-width-1-1@s">
                                <label className="uk-form-label">Name*</label>
                                <input
                                    className={`uk-input ${error.nameError !== "" ? 'red-border' : ''}`}
                                    type="text"
                                    placeholder=""
                                    name="name"
                                    value={form.name}
                                    onChange={onUpdateField}
                                />
                            </div>

                            <div className="uk-width-1-2@s uk-child-width-1-1@s half-width">
                                <label className="uk-form-label">Telephone*</label>
                                <input
                                    className={`uk-input ${error.phoneError !== "" ? 'red-border' : ''}`}
                                    type="text"
                                    placeholder=""
                                    aria-label="50"
                                    name="phone"
                                    value={form.phone}
                                    onChange={onUpdateField}
                                />
                            </div>

                            <div className="input-margin uk-width-1-2@s uk-child-width-1-1@s">
                                <label className="uk-form-label">Email</label>
                                <input
                                    className="uk-input"
                                    type="text"
                                    placeholder=""
                                    name="email"
                                    value={form.email}
                                    onChange={onUpdateField}
                                />
                            </div>

                            <div className="uk-width-1-2@s uk-child-width-1-1@s half-width">
                                <label className="uk-form-label" for="form-stacked-select">Enquiry Type*</label>
                                <select
                                    className={`uk-input ${error.enquiryError !== "" ? 'red-border' : ''}`}
                                    id="form-stacked-select"
                                    name="enquiry"
                                    value={form.enquiry}
                                    onChange={onUpdateField}
                                >
                                    <option value="" disabled selected>Choose enquiry type</option>
                                    <option>New Boiler</option>
                                    <option>Boiler servicing</option>
                                    <option>Boiler repairs</option>
                                    <option>Free service reminder</option>
                                    <option>Submit a Question</option>
                                    <option>Free Survey</option>
                                    <option>Other</option>
                                </select>
                            </div>

                            <div className="input-margin uk-width-1-1">
                                <label className="uk-form-label" for="form-stacked-select">Additional Information</label>
                                <textarea
                                    className="uk-textarea"
                                    rows="5"
                                    placeholder=""
                                    aria-label="Textarea"
                                    name="addInfo"
                                    value={form.addInfo}
                                    onChange={onUpdateField}
                                ></textarea>
                            </div>

                            <div className="input-news uk-width-1-1">
                                <label className="uk-form-label">Where did you hear about us?*</label>
                                <input
                                    className={`uk-input ${error.aboutError !== "" ? 'red-border' : ''}`}
                                    type="text"
                                    placeholder=""
                                    aria-label="100"
                                    name="about"
                                    value={form.about}
                                    onChange={onUpdateField}
                                />
                            </div>


                            <div className="form-footer-contact">

                                <div className="btn">
                                    <Button
                                        onClick={() => handleGtagContactForm()}
                                        isSubmit="true"
                                        btnType="secondary"
                                        label={`${loading == false ? 'Send Enquiry' : 'Loading...'}`}
                                        additionalClass="contact-btn"
                                    />
                                </div>

                                {
                                    msgError ? <p className="error-msg">One or more fields have an error. Please check and try again.</p> : ""
                                }
                                {
                                    message ? <p className="error-message">{message}</p> : ""
                                }

                                <p>We will only use your information to respond to your enquiry.</p>
                            </div>

                        </form>



                    </div>
                </div >
            </div >

            <div className="contact-page-container-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.4054041548375!2d-3.057454284261636!3d53.55053018002234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b3b19ea7c49f1%3A0xb595cfd32bf6ce6b!2sTower%20Heating!5e0!3m2!1sen!2sro!4v1668761011155!5m2!1sen!2sro &maptype=satellite" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>




        </Layout >
    )
}

export default Contact